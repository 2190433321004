import Vue from 'vue'
import { Tab, Tabs } from 'vant';
import App from './App.vue'
import 'reset.css'
import './global.less'
import router from './router'

Vue.config.productionTip = false

Vue.use(Tab);
Vue.use(Tabs);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
