<template>
    <div class="about">
      <div class="image-container">
        <img src="@/assets/images/top4.jpg" alt="奖池" class="main-image">
        <span class="centered-text">{{items.total_count}}</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PageHome',
    data() {
      return {
        items: [], // 添加 items 数组用于存储数据
      };
    },
    created() {
    this.fetchData(); // 组件创建时调用 fetchData 方法
  },
  methods: {
    getWeekTimestamps() {
      const now = new Date();
      const dayOfWeek = now.getDay(); // 获取今天是周几（0 为周日）
      const offset = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // 计算离周一的偏移量
      const monday = new Date(now.setDate(now.getDate() - offset)); // 获取周一的日期
      monday.setHours(0, 0, 0, 0); // 设置周一零点
      
      const sunday = new Date(monday);
      sunday.setDate(sunday.getDate() + 6); // 获取周日的日期
      sunday.setHours(23, 59, 59, 999); // 设置周日 23:59:59

      return {
        startOfWeek: Math.floor(monday.getTime() / 1000), // 周一零点的时间戳（秒）
        endOfWeek: Math.floor(sunday.getTime() / 1000), // 周日 23:59:59 的时间戳（秒）
      };
    },
    fetchData() {
      const weekTimestamps = this.getWeekTimestamps();
      const startOfWeek = weekTimestamps.startOfWeek;
      const endOfWeek = weekTimestamps.endOfWeek;
      const apiUrl = `/api/appapi/activity/rank_agent_share_card?activityStartTime=${startOfWeek}&activityEndTime=${endOfWeek}`;
      fetch(apiUrl)
        .then(response => {
          console.log('Response:', response);
          if (!response.ok) {
            throw new Error(`网络响应不正常，状态码: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.items = data.data; // 将获取到的新数据赋值给 items
        })
        .catch(error => {
          console.error('发生错误:', error);
        });
    },
  },
  };
  </script>
  
  <style lang="less" scoped>
  .about {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 20px;
  }
  
  .image-container {
    position: relative;
    width: 100%;
    max-width: 400px; // 根据需要调整最大宽度
  }
  
  .main-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .centered-text {
    position: absolute;
    top: 82%;
    left: 62%;
    transform: translate(-50%, -50%);
    color: white; // 根据需要调整颜色
    // font-size: 24px; // 调整字体大小
    font-weight: bold;
    text-align: center;
  }
  </style>
  