<template>
  <div class="home">
    <van-tabs>
      <van-tab title="榜单" class="home-container">
        <div class="app-container">
          <img class="tab-pic" src="" >
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img :src="items[1].avatar" alt="">
                  <p>{{items[1].user_nicename}}</p>
                </div>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img :src="items[0].avatar" alt="">
                  <p>{{items[0].user_nicename}}</p>
                </div>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img :src="items[2].avatar" alt="">
                  <p>{{items[2].user_nicename}}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="container-title" >
            <ul >
              <li >
                <span class="container-title-types">瓜分卡</span>
                <span class="container-title-quantity">{{ items[1].count }}张</span>
              </li>
              <li>
                <span class="container-title-types">瓜分卡</span>
                <span class="container-title-quantity">{{ items[0].count }}张</span>
              </li>
              <li >
                <span class="container-title-types">瓜分卡</span>
                <span class="container-title-quantity">{{ items[2].count }}张</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>瓜分卡(张)</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="(item,index) in items.slice(3)" :key="item.ranking" >
                <span>{{index+4}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.user_nicename }}</span>
                <span>{{ item.count }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="规则" class="home-container">
        <div class="app-container">
          <img class="top-pic" src="~@/assets/images/规则.png" alt="">
        </div>
      </van-tab>
      <van-tab title="奖池" class="home-container">
        <jiangli></jiangli>
        <!-- <img class="top-pic" src="~@/assets/images/top4.jpg" alt=""> -->
      </van-tab>
      <van-tab title="历史榜单" class="home-container">
        <Past></Past>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import  Past from '@/components/past.vue';
import  jiangli from '@/components/jiangli.vue';
export default {
  name: 'PageHome',
  components: {
    Past,
    jiangli
  },
  data() {
    return {
      // 观看
      userList :[
        {
          types: '瓜分卡',
          quantity: '',
        },   
        {
          types: '瓜分卡',
          quantity: '',
        },
        {
          types: '瓜分卡',
          quantity: '',
        }
      ],
      // 第一周
      items: [
        {
          ranking: 1,
          name: '不知名🦋',
          watch: '2250',
          image: 'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png',
        },
        {
          ranking: 2,
          name: '不知名🦋',
          watch: '2250',
          image: 'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png',
        },
        {
          ranking: 3,
          name: '不知名🦋',
          watch: '2250',
          image: 'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png',
        },        
        {
          ranking: 4,
          name: '不知名🦋',
          watch: '2250',
          image: 'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png',
        },        
        {
          ranking: 5,
          name: '不知名🦋',
          watch: '2250',
          image: 'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png',
        },        
        {
          ranking: 6,
          name: '不知名🦋',
          watch: '2250',
          image: 'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png',
        },        
        {
          ranking: 7,
          name: '不知名🦋',
          watch: '2250',
          image: 'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png',
        },
        {
          ranking: 8,
          name: '不知名🦋就观看',
          watch: '2250',
          image: 'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png',
        },
        {
          ranking: 9,
          name: '不知名🦋就观看',
          watch: '2250',
          image: 'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png',
        },
        {
          ranking: 10,
          name: '不知名🦋就观看1',
          watch: '2250',
          image: 'https://qnlive.dgshwhcb.top/108281_IOS_20240624052642_cerFace.png',
        },
      ],

    }
  },
  created() {
    this.fetchData(); // 组件创建时调用 fetchData 方法
  },
  methods: {
    getWeekTimestamps() {
      const now = new Date();

    // 获取当前时间的 UTC 时间戳
    const utcNow = new Date(now.toLocaleString('en-US', { timeZone: 'Asia/Shanghai' }));

    // 获取今天是周几（0 为周日）
    const dayOfWeek = utcNow.getDay();

    // 计算离周一的偏移量
    const offset = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // 0 表示周日

    // 获取周一的日期并设置为零点
    const monday = new Date(utcNow.setDate(utcNow.getDate() - offset));
    monday.setHours(0, 0, 0, 0); // 设置周一零点（北京时间）

    // 获取周日的日期并设置为 23:59:59
    const sunday = new Date(monday);
    sunday.setDate(sunday.getDate() + 6); // 获取周日
    sunday.setHours(23, 59, 59, 999); // 设置周日 23:59:59（北京时间）

      return {
        startOfWeek: Math.floor(monday.getTime() / 1000), // 周一零点的时间戳（秒）
        endOfWeek: Math.floor(sunday.getTime() / 1000), // 周日 23:59:59 的时间戳（秒）
      };
    },
    fetchData() {
      const weekTimestamps = this.getWeekTimestamps();
      const startOfWeek = weekTimestamps.startOfWeek;
      const endOfWeek = weekTimestamps.endOfWeek;
      const apiUrl = `/api/appapi/activity/rank_agent_share_card?activityStartTime=${startOfWeek}&activityEndTime=${endOfWeek}`;
      fetch(apiUrl)
        .then(response => {
          console.log('Response:', response);
          if (!response.ok) {
            throw new Error(`网络响应不正常，状态码: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.items = data.data.rankInviteList; // 将获取到的新数据赋值给 items
        })
        .catch(error => {
          console.error('发生错误:', error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.top-pic{
  width: 100%;
}
.home {
  font-size: 14px;
  background:url('~@/assets/images/about-bg.jpg') no-repeat top left / 100%;
  width: 100vw;
  margin-bottom: 34px;
  .van-tabs {
    width: 343px;
    margin: 0 auto;
    padding-top: 260px;
  }
  /deep/ .van-tab {
    color: #972300;
    font-size: 18px;
    font-weight: 500;
    &--active{
      .van-tab__text.van-tab__text--ellipsis{
        color: #fff;
      }
    }
    &__text{
      color: #fff;
      position: relative;
      z-index: 2;
    }
  }
  /deep/ .van-tabs--line .van-tabs__wrap{
    height: 38px !important;
    border-radius: 18px;
    margin-top: 200px
  }
  /deep/ .van-tabs__line {
    background:linear-gradient(to bottom,#e3be98,#ff4f04);
    width: 86px;
    height: 38px;
    border-radius: 18px;
  }
  /deep/ .van-tab__text--ellipsis {
    color: #972300;
  }
  .home-container {
    padding-top: 25px;
    background: url('~@/assets/images/bg.png') no-repeat top left / 100%;
  }
  .tab-pic{
    width: 180px;
    // height: 45px;
    position: absolute;
    top: -20px;
    left: 80px;
  }
  .app-container{
    position: relative;
    width: 341px;
    margin: 4px auto;
    height: 534px;
    background: url('~@/assets/images/content1.png') no-repeat top left / 100%;
    margin-right: 10px;
  }
  .container-top{
    ul{
      display: flex;
      justify-content: space-around;
      li{
        margin-top: 40px;
      }
      .ul-chidern1{
        position: relative;
        margin-top: 60px;
        width:106px;
        height:132px;
        background: url('~@/assets/images/top2.png')no-repeat top left / 100%;
        margin-left: 6px;
        .ul-chidern1-conent{
          width: 50px;
          height: 50px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 22px;
          margin-top: 6px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
          p{
            font-size: 12px;
            text-align: center;
            padding: 4px 0;
          }
        }
        .ul-chidern1-conent-title{
          width: 105px;
          padding-top: 30px;
          text-align: left;
          margin-left: 0 !important;
          span {
          font-size: 10px;
          color: #550e00;
          }
        }
        .ul-chidern1-conent::after{
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            background: url('~@/assets/images/tab2.png')no-repeat top left / 100%;
            position: absolute;
            top: -10px;
            left: 6px;
          }
      }
      .ul-chidern2{
        position: relative;
        margin-top: 40px;
        width:106px;
        height:144px;
        background: url('~@/assets/images/top1.png')no-repeat top left / 100%;
        margin-left: 6px;
        .ul-chidern2-conent{
          width: 58px;
          height: 58px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 24px;
          margin-top: 6px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
          p{
            font-size: 12px;
            text-align: center;
            padding: 4px 0;
          }
        }
        span{
          display: block;
          width: 100%;
          text-align: left;
          color: #fff;
          font-size: 10px;
          margin-top: 55px;
          margin-left: 6px;
          color: #550e00;
        }
        .ul-chidern2-conent::after{
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            background: url('~@/assets/images/tab1.png')no-repeat top left / 100%;
            position: absolute;
            top: -12px;
            left: 14px;
          }
      }
      .ul-chidern3{
        margin-right: 8px;
        margin-top: 60px;
        width:105px;
        height:138px;
        background: url('~@/assets/images/top3.png')no-repeat top left / 100%;
        margin-left: 6px;
        .ul-chidern3-conent{
          position: relative;
          width: 50px;
          height: 50px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 24px;
          margin-top: 6px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
          p{
            padding: 4px 0;
            font-size: 12px;
            text-align: center;
          }
        }
        span{
          display: block;
          width: 100%;
          text-align: left;
          color: #fff;
          font-size: 10px;
          margin-top: 54px;
          margin-left: 6px;
          color: #550e00;
        }
        .ul-chidern3-conent::after{
          content: '';
            display: block;
            width: 42px;
            height: 34px;
            background: url('~@/assets/images/tab3.png')no-repeat top left / 100%;
            position: absolute;
            top: -16px;
            left: -20px;
          }
      }
    }
    }
  .container-title{
    font-weight: 600;
    color: #b04400;
    margin-top: -30px;
    ul {
    display: flex; /* 启用 Flexbox 布局 */
    gap: 2px; 
    li {
    flex: 1; /* 每个列表项占据等宽 */
    color: #550e00;
      }
      span:nth-child(1) {
        width: 50px;
        padding: 0 14px;
        text-align: left;
        font-size: 10px;
      }
      span:nth-child(2) {
        width: 50px;
        text-align: right;
        font-size: 12px;
        color: rgb(187, 16, 16);
      }
    }
  }
  // .container-title::after{
  //   content: '';
  //   display: block;
  //   width: 40px;
  //   height: 40px;
  // }
  .container-center{
    color: #b04400;
    font-weight: 600;
    margin-top:24px;
    font-size: 16px ;
    ul{
      margin-left: 10px;
      width: 320px;
      display: flex;
      justify-content: space-around;
      padding-bottom: 10px;
      border-bottom: 1px dashed #b04400;
      li{
        display: flex;
        justify-items: center;
        margin-left: -14px;
      }

    }
  }
  .container-bottom{
    ul{
      overflow-y: auto;
      margin-left: 8px;
      margin-top: 10px;
      width: 324px;
      height: 254px;
      li {
        font-size: 13px;
        padding: 2px 0;
        span:nth-child(1) {
          text-align: center;
          width: 26px;
          display: inline-block;
        }
        span:nth-child(2) {
          display: inline-block;
          text-align: center;
          width: 90px;
          border-radius: 100%;
          margin-left: 5px;
          img {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            text-align: center;
          }
        }
        span:nth-child(3) {
          text-align: center;
          display: inline-block;
          width: 120px;
          padding-right: 12px;
          white-space: nowrap;        /* 不换行 */
          overflow: hidden;           /* 隐藏溢出的内容 */
          text-overflow: ellipsis;
        }
        span:nth-child(4) {
          width: 60px;
          display: inline-block;
          text-align: center;
          // text-align: left;
          white-space: nowrap;        /* 不换行 */
          overflow: hidden;           /* 隐藏溢出的内容 */
          text-overflow: ellipsis;
        }
      }
      li:nth-child(even) {
        height: 32px;
        width: 100%; /* 偶数项背景色 */
        background-color: #f9dbbe;
      }
      li:nth-child(odd) {
        height: 34px;
        width: 100%;
 /* 偶数项背景色 */
      }
    }
  }
}
</style>