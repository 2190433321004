import Vue from 'vue'
import Router from 'vue-router'
import PageHome from '../pages/page-home.vue'
import past from '@/components/past.vue'
import PageAbout from '../components/page-about.vue'
import jiangli from '@/components/jiangli.vue'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'PageHome',
    component: PageHome
  },
  {
    path: '/about',
    name: 'PageAbout',
    component: PageAbout
  },
  {
    path: '/past',
    name: 'Past',
    component: past
  },
  {
    path: '/jiangli',
    name: 'Jiangli',
    component: jiangli
  },
]

const router = new Router({
  mode: 'history',
  routes
});

export default router